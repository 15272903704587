import _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../config";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";

//_______________________________________________________________________________
axios.defaults.baseURL = AppConfig.baseURL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";
axios.defaults.headers.patch["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
  "idToken"
)}`;
//_______________________________________________________________________________

const migrateUserAccountBaseURL = AppConfig.migrationURL;

const LocationListURL = AppConfig.baseURL + "/countries";
const OrganisationDetailsPartialURL = AppConfig.baseURL + "/account?id=";
const OrganisationListURL = AppConfig.baseURL + "/accounts";
const ConsentStatusUpdationURL =
  AppConfig.baseURL + "/account/changeConsentStatus";

const AddOrganisationURL = AppConfig.baseURL + "/account";
const EditOrganisationURL = AppConfig.baseURL + "/account";
const ReactivateAccountURL = AppConfig.baseURL + "/reactivate-account";
const GetLinkedOrganisationsURL = AppConfig.baseURL + "/account/filter";
const LinkOrganizationURL = AppConfig.baseURL + "/link-account";
const OrgTypeDetailsPartialURL = AppConfig.baseURL + "/account-types";
const TransferChildOrgList = OrganisationListURL + "/ht-transferChildList";
const TypeListURL = AppConfig.baseURL + "/account-types";

const AddFamilyURL = AppConfig.baseURL + "/ht-family";
const AddFamilyMemberURL = AppConfig.baseURL + "/ht-family/member";
const FamilyListURL = AppConfig.baseURL + "/ht-family/list";
const EditFamilyStatusURL = AppConfig.baseURL + "/ht-family";
const EditFamilyMemberURL = AppConfig.baseURL + "/ht-family/member";
const EditFamilyURL = AppConfig.baseURL + "/ht-family";
const GetFamilyDetails = AppConfig.baseURL + "/ht-family?family_id=";

const LanguageListURL = AppConfig.baseURL + "/languages";

const RelationListURL = AppConfig.baseURL + "/ht-family/relation";
const MemberTypeListURL = AppConfig.baseURL + "/ht-family/membertype";

const UserListURL = AppConfig.baseURL + "/users";
const UserRoleList = AppConfig.baseURL + "/user-roles";
const UserDetailsPartialURL = AppConfig.baseURL + "/user?id=";
const AddUserURL = AppConfig.baseURL + "/user";
const EditUserURL = AppConfig.baseURL + "/user";
const UserEmailURL = AppConfig.baseURL + "/user/checkUniqueEmail?email=";
const UserAccountExistanceURL = AppConfig.baseURL + "/user/login/validate";
const migrateUserAccountURL = migrateUserAccountBaseURL + "/update/id";
const resetTokenURL =
  "https://iauul2jne9.execute-api.us-east-1.amazonaws.com/dev/reset-token";
const ForgotPasswordURL = AppConfig.baseURL + "/user/forgot-password";
const UpdatePasswordURL = AppConfig.baseURL + "/user/update-password";
const NewPasswordOnRegistrationURL = AppConfig.baseURL + "/user/register";
const validateTSRoleChangeURL = AppConfig.baseURL + "/validate-ht-role-change";
const validateFSRoleChangeURL = AppConfig.baseURL + "/validate-fs-role-change";
const ValidateUserDeactivationTSURL =
  AppConfig.baseURL + "/validate-ht-user-deactivation";
const ValidateUserDeactivationFSURL =
  AppConfig.baseURL + "/fs-user-deactivation-check";
const ChildListURL = AppConfig.baseURL + "/ht-child/list";
const ChildDetailsPartialURL = AppConfig.baseURL + "/ht-child?child_id=";
const AddChildURL = AppConfig.baseURL + "/ht-child";
const ChildDocURL = AppConfig.baseURL + "/ht-child/file";
const ChildDocumentListURL = AppConfig.baseURL + "/ht-child/get-files";
const ChildPlacementStatusURL = AddChildURL + "/placementStatus";
const ChildStatusURL = AddChildURL + "/childStatus";
const ChildCurrentPlacementURL = AddChildURL + "/currentPlacementStatus";
const ChildEducationLevelURL = AddChildURL + "/educationLevels";
const TransferChildURL = AddChildURL + "/transfer";
const ChildAuditURL = AddChildURL + "/auditlog";
const fileUploadURL = AppConfig.baseURL + "/create-url-for-upload";

const CaseListURL = AppConfig.baseURL + "/ht-case/list";
const AddCaseURL = AppConfig.baseURL + "/ht-case";
const CaseDetailsPartialURL = AppConfig.baseURL + "/ht-case?case_id=";
const CloseCaseURL = AppConfig.baseURL + "/case/close";
const ChildConsentURL = AppConfig.baseURL + "/ht-consent/listChildConsent";

const FormListURL = AppConfig.baseURL + "/ht-form";
const DomainListURL = AppConfig.baseURL + "/ht-question/domain";
const AddFormQuetionsURL = AppConfig.baseURL + "/form/questions";
const MappedQuetionsURL = AppConfig.baseURL + "/form/mappedQuestions";
const PreviewFormQuestionsURL = AppConfig.baseURL + "/ht-form/preview?formId=";
const NewPreviewFormQuestionsURL =
  AppConfig.baseURL + "/ht-form/getFormDetails";
const PublishForm = AppConfig.baseURL + "/ht-form/publish";
const UpdateFormURL = AppConfig.baseURL + "/ht-form/updateForm";
const UpdateFormStatus = AppConfig.baseURL + "/ht-form/updateFormStatus";
const SaveNewFormURL = AppConfig.baseURL + "/ht-form/saveNewForm";

const getFormDetailsUrl = AppConfig.baseURL + "/ht-form/getFormDetails";

const VisitTypeListURL = AppConfig.baseURL + "/ht-assessment/visittypes";
const ReIntegrationTypeListURL =
  AppConfig.baseURL + "/ht-assessment/reintegrationtypes";
const AssessmentListURL = AppConfig.baseURL + "/ht-assessment/list";
const SaveResponseURL = AppConfig.baseURL + "/assessment/save/response";

const QuestionDomainListURL = AppConfig.baseURL + "/ht-question/domain";
const QuestionTypeListURL = AppConfig.baseURL + "/ht-question/type";
const QuestionListURL = AppConfig.baseURL + "/ht-question/list";
const GetQuestionDetails = AppConfig.baseURL + "/ht-question?question_id=";
const GetQuestionDetailsForEdit =
  AppConfig.baseURL + "/ht-question/for/edit?question_id=";

const AnswerTypeListURL = AppConfig.baseURL + "/ht-question/answerType";
const CheckIsQuestionUniqueURL =
  AppConfig.baseURL + "/ht-question/check/unique";
const CreateQuestionURL = AppConfig.baseURL + "/ht-question";
const EditQuestionURL = AppConfig.baseURL + "/ht-question";
const EditQuestionStatusURL = AppConfig.baseURL + "/ht-question";
const DeleteQuestionURL = AppConfig.baseURL + "/ht-question";

const ListFormQuestionsURL =
  AppConfig.baseURL + "/ht-assessment/getassessmentform?formId=";
const GetAssessmentDetailsURL =
  AppConfig.baseURL + "/ht-assessment/getassessmentdetails?assessmentId=";
const CalculateScoreURL = AppConfig.baseURL + "/ht-score/calculate";
const ScoreURL = AppConfig.baseURL + "/ht-score?childId=";

const UploadURL = AppConfig.baseURL + "/fileUploads/";
const UploadedFilesURL = AppConfig.baseURL + "/fileUploads";

const UploadCSVURL = AppConfig.baseURL + "/fileUploads/importFile/";
const CsvHeaderValue = AppConfig.baseURL + "/importFile/mapdata?documentId=";
const ActualImport = AppConfig.baseURL + "/importFile";

const ListEventsURL = AppConfig.baseURL + "/ht-events/list";
const CreateEventsURL = AppConfig.baseURL + "/ht-events/create";
const DeleteEventsURL = AppConfig.baseURL + "/ht-events";
const EditEventsURL = AppConfig.baseURL + "/ht-events";

const ChildServedReportURL = AppConfig.baseURL + "/report/ht-childrenserved";
const ChildRedFlagReportURL =
  AppConfig.baseURL + "/report/children/ht-redflags";
const DurationFollowupReportURL =
  AppConfig.baseURL + "/report/ht-durationFollowup";
const ChildOverdueReportURL = AppConfig.baseURL + "/report/ht-childoverdue";
const NewlyAddedChildrenReportURL =
  AppConfig.baseURL + "/report/ht-newlyadmitted";
const CaseManagementReportURL = AppConfig.baseURL + "/report/ht-casemanagement";
const CaseworkerServedURL = AppConfig.baseURL + "/report/caseworkerserved";
const FamiliesServedURL = AppConfig.baseURL + "/report/familyserved";
const DashboardOverallURL = AppConfig.baseURL + "/report/ht-dashboardOverall";
const FSDashboardOverallURL = AppConfig.baseURL + "/fs-reports";

const ExportURL = AppConfig.baseURL + "/exportFile";
const AverageThriveURL = AppConfig.baseURL + "/report/ht-averageThrivescale";
const FollowupURL = AppConfig.baseURL + "/report/durationFollowup";
const OverdueURL = AppConfig.baseURL + "/report/childoverdue";
const NewlyAdmittedURL = AppConfig.baseURL + "/report/ht-newlyadmitted";
const DisruptionCasesURL = AppConfig.baseURL + "/report/ht-disruptionCase";
const CaseManagementURL = AppConfig.baseURL + "/report/ht-casemanagement";
const ChildrenInCCIURL = AppConfig.baseURL + "/report/current/ht-cciresident";
const CurrentPlacementURL = AppConfig.baseURL + "/report/current/ht-placement";
const DurationInCCIURL = AppConfig.baseURL + "/report/cci/ht-duration";
const ReintegratedChildrenURL =
  AppConfig.baseURL + "/report/reintegrated/ht-children";
const NewlyAddedURL = AppConfig.baseURL + "/report/ht-newlyadded";
const InterventionDetailsURL =
  AppConfig.baseURL + "/report/ht-interventiondetails";
const MapReportURL = AppConfig.baseURL + "/report/ht-map";
const AverageChangeURL =
  AppConfig.baseURL + "/report/ht-avgPercentChangeTScore";
const EditProfileURL = AppConfig.baseURL + "/user/profile";
const ResendInvitationURL = AppConfig.baseURL + "/user/resendInvite";

const NotificationListURL = AppConfig.baseURL + "/notification/list";
const UpdateNotificationURL = AppConfig.baseURL + "/notification/update";
const saveUserLanguageURL = AppConfig.baseURL + "/user/updateFields";
const ProgressReportListURL =
  AppConfig.baseURL + "/ht-followup/getFollowUpChildList";
const viewFollowUpProgressURL =
  AppConfig.baseURL + "/ht-followup/viewFollowUpProgress";
const getFollowupDomainDetailsURL =
  AppConfig.baseURL + "/ht-followup/getFollowupDomainDetails";
const generarateProgressReportListURL =
  AppConfig.baseURL + "/ht-followup/generateFollowUpProgressListExcel";
const generateFollowUpProgressPdfURL =
  AppConfig.baseURL + "/ht-followup/generateFollowUpProgressPdf";

// fs child api URLs
const fsChildURL = AppConfig.baseURL + "/child";
const fsChildListURL = AppConfig.baseURL + "/children";
const getFsChildURL = AppConfig.baseURL + "/child?id=";
const getFsChildPlacementStatusURL =
  AppConfig.baseURL + "/child-placement-status";

const childDischargeReasonsListURL =
  AppConfig.baseURL + "/child-discharge-reason";

// fs family api URLs
const fsFamilyURL = AppConfig.baseURL + "/fs-families";
const fsParentsURL = AppConfig.baseURL + "/user/parents";
const getFsFamilyURL = AppConfig.baseURL + "/fs-family";
const fsFamilyDropdownURL = AppConfig.baseURL + "/fs-family-list";
const GetFamilyImagesURL = AppConfig.baseURL + "/fs-family-images";
const getMessageListURL = AppConfig.baseURL + "/fs-messages";
const createScheduleMessageURL = AppConfig.baseURL + "/fs-scheduled-message";
const createMessageURL = AppConfig.baseURL + "/fs-message";
const getScheduledMessageListURL = AppConfig.baseURL + "/fs-scheduled-messages";
const getFamilyPerCaseWorkerURL =
  AppConfig.baseURL + "/fs-reports/getFamiliesPerUser";
const getCaseManagerChildPerAccountURL =
  AppConfig.baseURL + "/fs-reports/getCaseManager-Child-PerAccount";

const getChildPerCaseWorker =
  AppConfig.baseURL + "/report/ht-childrenPerCaseworker";

// fs family api URLs
const fsEventsURL = AppConfig.baseURL + "/events";
const fsEventsActionURL = AppConfig.baseURL + "/event";

// log api url
const ListRecLogURL = AppConfig.baseURL + "/form-engines/list-logs";
const DetailRecLogURL = AppConfig.baseURL + "/form-engines/child-log";
const MedicationListURL = AppConfig.baseURL + "/form-engines/list-entity";
const getSignedMedLogDocURL =
  AppConfig.baseURL + "/form-engines/get-medlog-document";
const getLogImagesDocURL =
  AppConfig.baseURL + "/form-engines/adapter-functions";
const ExportLogPdfURL = AppConfig.baseURL + "/fs-reports/export-log-pdf";
const getSupportServiceListURL = AppConfig.baseURL + "/fs-support-services";
const getSupportServiceDetailURL = AppConfig.baseURL + "/fs-support-service";
const getResourceListURL = AppConfig.baseURL + "/fs-resources";
const getResourceCategoriesListURL =
  AppConfig.baseURL + "/fs-resource-categories";
const getResourceDetailURL = AppConfig.baseURL + "/fs-resource";

// notifications api urls
const ListNotificationsURL = AppConfig.baseURL + "/user/notification/web";
const AcceptTermsAndPrivacyPolicyURL =
  AppConfig.baseURL + "/user/acceptTermsOfUse";

// System message API URLs
const CreateSystemMessagesURL = AppConfig.baseURL + "/system-messages";
const SystemMessageUrl = "/system-messages";
const systemMessageURL = AppConfig.baseURL + "/system-messages/userMessageList";
const updateSystemMessageReadStatusURL =
  AppConfig.baseURL + "/system-messages/readstatus-update";
const messageDetailURL = AppConfig.baseURL + "/system-messages/details";
const checkIfAppDepricatedURL =
  AppConfig.baseURL + `/deprication/checkIfAppDepricated?app=THRIVEWELL`;

const APIS = {
  TypeList(params = {}) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(TypeListURL, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  forgotPassword(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ForgotPasswordURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  updatePassword(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(UpdatePasswordURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  NewPasswordOnRegistration(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NewPasswordOnRegistrationURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    });
  },

  LocationList(langId) {
    let CompletedLocationListURL = LocationListURL;
    //const userRegion = localStorage.getItem("userRegion");
    //let userRole = localStorage.getItem("role");
    // if (langId) {
    //   CompletedLocationListURL =
    //     LocationListURL +
    //     `?languageId=${langId}` +
    //     `&HTCountryId=${userRole === "superadmin"
    //       ? userRegion === "india"
    //         ? userRegion
    //         : ""
    //       : userRegion
    //     }`;
    // } else {
    //   CompletedLocationListURL =
    //     LocationListURL +
    //     `?HTCountryId=${userRole === "superadmin"
    //       ? userRegion === "india"
    //         ? userRegion
    //         : ""
    //       : userRegion
    //     }`;
    // }
    const payload = {
      languageId: langId,
    };
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CompletedLocationListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  VisitTypeList(langId) {
    let CompletedVisitTypeListURL = VisitTypeListURL;
    if (langId) {
      CompletedVisitTypeListURL = VisitTypeListURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedVisitTypeListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ReIntegrationTypeList(langId) {
    let CompletedReIntegrationTypeListURL = ReIntegrationTypeListURL;
    if (langId) {
      CompletedReIntegrationTypeListURL =
        ReIntegrationTypeListURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedReIntegrationTypeListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CaseList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CaseListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  LanguageList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(LanguageListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  RelationList(langId) {
    let CompletedRelationListURL = RelationListURL;
    if (langId) {
      CompletedRelationListURL = RelationListURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedRelationListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  MemberTypeList(langId) {
    let CompletedMemberTypeListURL = MemberTypeListURL;
    if (langId) {
      CompletedMemberTypeListURL = MemberTypeListURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedMemberTypeListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  OrganisationDetails(id) {
    if (id) {
      let prerequest = this.PreRequestCall();
      return axios.all([prerequest]).then((res) => {
        const OrganizationDetailsCompletedURL =
          OrganisationDetailsPartialURL + id;
        return axios
          .get(OrganizationDetailsCompletedURL)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  },
  OrganizationList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(OrganisationListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getCaseManagerChildPerAccount(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getCaseManagerChildPerAccountURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  LinkedOrganizationList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(GetLinkedOrganisationsURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  LinkedTransferOrganizationList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(TransferChildOrgList)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  AddOrganization(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddOrganisationURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  EditOrganization(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditOrganisationURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  ReActiavteOrganization(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(ReactivateAccountURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  deactivateOrganization(payload) {
    let prerequest = this.PreRequestCall();
    console.log("EditOrganisationURL", payload);
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(EditOrganisationURL, { data: payload })
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  ChangeConsentStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(ConsentStatusUpdationURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  AcceptTermsAndPrivacyPolicy(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(AcceptTermsAndPrivacyPolicyURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  checkOrganizationName(id, name) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`IsUniqueAccount?id=${id}&accountName=${name}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  ChangeOrganizationStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(EditOrganisationURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  AddFamily(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddFamilyURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },

  FamilyDetails(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const GetFamilyDetailsURL = GetFamilyDetails + id;
      return axios
        .get(GetFamilyDetailsURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ListUsers(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(UserListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  UserRoleListFS(langId = "1") {
    let prerequest = this.PreRequestCall();
    const payload = {
      type: "FS",
      languageId: langId,
    };
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(UserRoleList, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  UserRoleListHT(langId = "1") {
    let prerequest = this.PreRequestCall();
    const payload = {
      type: "HT",
      languageId: langId,
    };
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(UserRoleList, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  GetLanguagesList(accessType) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`/languages?accessType=${accessType}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  UserDetails(id) {
    if (_.isNil(id) || id === "undefined") {
      return;
    }
    if (id) {
      console.log("user", id);
      let prerequest = this.PreRequestCall();
      return axios.all([prerequest]).then((res) => {
        const UserCompletedURL = UserDetailsPartialURL + id;
        return axios
          .get(UserCompletedURL)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  },
  AddUser(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddUserURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },

  ChangeFamilyStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(EditFamilyStatusURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  EditFamily(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditFamilyURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },

  EditUser(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditUserURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  AddFamilyMember(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddFamilyMemberURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },

  EditFamilyMember(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditFamilyMemberURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },

  FamilyList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(FamilyListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ChangeUserStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(EditUserURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ValidateUserDeactivationTS(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ValidateUserDeactivationTSURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ValidateUserDeactivationFS(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ValidateUserDeactivationFSURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CheckUserEmailExists(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const UserCompletedURL = UserEmailURL + id;
      return axios
        .get(UserCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },
  CheckUserAccountExist(id) {
    //let prerequest = this.PreRequestCall();
    const payload = {
      email: id,
    };
    return axios.all([]).then((res) => {
      return axios
        .post(UserAccountExistanceURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  validateRoleChange(payload, name) {
    let prerequest = this.PreRequestCall();
    let APIURL =
      name === "FSRole" ? validateFSRoleChangeURL : validateTSRoleChangeURL;
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(APIURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  migrateUserToNewDB(payload) {
    //let prerequest = this.PreRequestCall();
    return axios.all([]).then((res) => {
      return axios
        .post(migrateUserAccountURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ResetToken(token) {
    //let prerequest = this.PreRequestCall();
    const payload = {
      refreshToken: token,
    };
    return axios.all([]).then((res) => {
      return axios
        .post(resetTokenURL, payload)
        .then((response) => {
          this.PreRequestCall().then(() => {
            return response;
          });
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  refresh() {
    Auth.currentAuthenticatedUser().then((currentUser) => {
      return Auth.currentSession().then((data) => {
        //data.getAccessToken().getJwtToken();
        currentUser.refreshSession(data?.refreshToken, (err, session) => {
          const { idToken, refreshToken, accessToken } = session;
          localStorage.setItem("accessToken", accessToken.jwtToken);
          localStorage.setItem("refreshToken", refreshToken.jwtToken);
          if (localStorage.getItem("keepLoggedIn") == "true") {
            localStorage.setItem("idToken", idToken.jwtToken);
          } else {
            sessionStorage.setItem("idToken", idToken.jwtToken);
          }
          axios.defaults.headers.common["Authorization"] = idToken.jwtToken;
        });
      });
    });
  },

  PreRequestCall() {
    return Auth.currentSession()
      .then((data) => {
        data.getAccessToken().getJwtToken();
        localStorage.setItem("accessToken", data.accessToken.jwtToken);
        localStorage.setItem("refreshToken", data.refreshToken.jwtToken);
        if (localStorage.getItem("keepLoggedIn") == "true") {
          localStorage.setItem("idToken", data.idToken.jwtToken);
        } else {
          sessionStorage.setItem("idToken", data.idToken.jwtToken);
        }
        axios.defaults.headers.common["Authorization"] = data.idToken.jwtToken;
      })
      .catch((err) => {
        console.log("error", err);
        localStorage.setItem("accessToken", "");
        localStorage.setItem("refreshToken", "");
        //localStorage.setItem("idToken", "");

        let idToken =
          localStorage.getItem("keepLoggedIn") == "true"
            ? localStorage.getItem("idToken")
            : sessionStorage.getItem("idToken");
        if (!_.isNil(idToken) && idToken !== "undefined") {
          //localStorage.setItem("username", null);
          localStorage.clear();
          let linkout = "/";
          console.log("console url", window.location.href);
          if (window.location.href == linkout) {
            //location.reload();
          } else {
            window.location.href = linkout;
          }
        }
      });
  },

  ListChildren(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ListChildrenConsent(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const ChildConsentCompletedURL =
        ChildConsentURL +
        `?HTChildId=` +
        payload.HTChildId +
        `&rowCount=` +
        payload.rowCount +
        `&pageNumber=` +
        payload.pageNumber;
      return axios
        .get(ChildConsentCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ChildDetails(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const ChildCompletedURL = ChildDetailsPartialURL + id;
      return axios
        .get(ChildCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  AddChild(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddChildURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  ChildPlacementStatus(langId) {
    let CompletedChildPlacementStatusURL = ChildPlacementStatusURL;
    if (langId) {
      CompletedChildPlacementStatusURL =
        ChildPlacementStatusURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedChildPlacementStatusURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildEducationList(langId) {
    let CompletedChildEducationLevelURL = ChildEducationLevelURL;
    if (langId) {
      CompletedChildEducationLevelURL =
        ChildEducationLevelURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedChildEducationLevelURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildStatus(langId) {
    let CompletedChildStatusURL = ChildStatusURL;
    if (langId) {
      CompletedChildStatusURL = ChildStatusURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedChildStatusURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  EditChild(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(AddChildURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },
  ChangeChildStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(AddChildURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },
  ChildCurrentPlacementStatus(langId) {
    let CompletedChildCurrentPlacementURL = ChildCurrentPlacementURL;
    if (langId) {
      CompletedChildCurrentPlacementURL =
        ChildCurrentPlacementURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedChildCurrentPlacementURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ListCases(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CaseListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  AddCase(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddCaseURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  EditCase(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(AddCaseURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },
  CaseDetails(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const CaseCompletedURL = CaseDetailsPartialURL + id;
      return axios
        .get(CaseCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  DeleteCase(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(AddCaseURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  FormList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(FormListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetFormDetails(payload) {
    let prerequest = this.PreRequestCall();
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );

    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    payload.langId = langId;

    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getFormDetailsUrl, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  FormPreviewQuestions(formId, HTLanguageId) {
    const PreviewFormCompletedURL =
      PreviewFormQuestionsURL + formId + `&HTLanguageId=${HTLanguageId}`;
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(PreviewFormCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  NewPreviewQuestions(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NewPreviewFormQuestionsURL, payload)
        .then((resonse) => {
          console.log("NewPreviewQuestions", resonse);
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  PublishForm(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(PublishForm, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  UpdateForm(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(UpdateFormURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  UpdateFormStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(UpdateFormStatus, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  CreateForm(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(SaveNewFormURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  DomainList() {
    let prerequest = this.PreRequestCall();
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    let CompletedDomainListURL = DomainListURL;
    if (langId) {
      CompletedDomainListURL = DomainListURL + `?languageId=${langId}`;
    }
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedDomainListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  QuestionDomainList(langId) {
    let CompletedQuestionDomainListURL = QuestionDomainListURL;
    if (langId) {
      CompletedQuestionDomainListURL =
        QuestionDomainListURL + `?languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(CompletedQuestionDomainListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  QuestionTypeList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(QuestionTypeListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ListQuestions(payload) {
    let prerequest = this.PreRequestCall();
    const currentLanguage = localStorage.getItem("language");
    console.log("jjjjjjjjjjjjjj", currentLanguage);
    // const currentLanguageList = JSON.parse(
    //   localStorage.getItem("languageList")
    // );
    // Todo - Change this later
    const currentLanguageList = [
      { id: "1", language: "English", languageCode: "en" },
      { id: "2", language: "Hindi", languageCode: "hi" },
      { id: "3", language: "Tamil", languageCode: "ta" },
    ];
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    payload.HTLanguageId = langId;
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(QuestionListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  QuestionDetails(id, language_id) {
    let GetQuestionDetailsURL = GetQuestionDetails + id;
    if (language_id) {
      GetQuestionDetailsURL =
        GetQuestionDetailsURL + "&language_id=" + language_id;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(GetQuestionDetailsURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  QuestionDetailsForEdit(id) {
    const GetQuestionDetailsURL = GetQuestionDetailsForEdit + id;
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(GetQuestionDetailsURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  AnswerTypeList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(AnswerTypeListURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CheckIsQuestionUnique(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CheckIsQuestionUniqueURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  CreateQuestion(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CreateQuestionURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  EditQuestion(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditQuestionURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  DeleteQuestion(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(DeleteQuestionURL, { data: payload })
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  AddFormQuetions(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AddFormQuetionsURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ChangeQuestionStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(EditQuestionStatusURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  GetMappedQuestions(payload) {
    let prerequest = this.PreRequestCall();
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    payload.HTLanguageId = langId;
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(MappedQuetionsURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  AssessmentList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AssessmentListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  SaveAssessmentResponse(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(SaveResponseURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ListAssessmentFormQuestions(formId, formRevision) {
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    let FormQuestionsCompletedURL =
      ListFormQuestionsURL + formId + `&formRevision=${formRevision}`;
    if (langId) {
      FormQuestionsCompletedURL =
        FormQuestionsCompletedURL + `&languageId=${langId}`;
    }
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(FormQuestionsCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetAssessmentDetails(
    assessmentId,
    formRevision,
    lastAssessmentDate,
    exportAssessment = false
  ) {
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    let GetAssessmentDetailCompletedURL;
    if (exportAssessment) {
      GetAssessmentDetailCompletedURL =
        GetAssessmentDetailsURL +
        assessmentId +
        `&formRevision=${formRevision}` +
        `&languageId=${langId}` +
        `&lastDateOfAssessment=${lastAssessmentDate}` +
        `&action=export`;
    } else {
      GetAssessmentDetailCompletedURL =
        GetAssessmentDetailsURL +
        assessmentId +
        `&formRevision=${formRevision}` +
        `&languageId=${langId}`;
    }

    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return (
        axios
          .get(GetAssessmentDetailCompletedURL)
          //return axios.get('https://lo3qexyas5.execute-api.us-east-1.amazonaws.com/dev-test/assessment/getassessmentdetails?assessmentId=7&formRevision=2')
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
          })
      );
    });
  },

  CalculateScore(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CalculateScoreURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ScoreForEachChild(childId) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const CompleteScoreURL = ScoreURL + childId;
      return axios
        .get(CompleteScoreURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },
  // UploadFile(payload) {
  //   let payloadURL = `${payload.moduleType}/${payload.documentType}?fileName=${payload.fileName}&moduleId=${payload.moduleId}&fileSize=${payload.fileSize}&description=${payload.description}`;
  //   let newUploadURl = UploadURL + payloadURL;
  //   let prerequest = this.PreRequestCall();
  //   return axios.all([prerequest]).then((res) => {
  //     return axios
  //       .post(newUploadURl)
  //       .then((response) => {
  //         return response;
  //       })
  //       .catch((error) => {
  //         let errorObject = {
  //           status: "",
  //           body: {},
  //         };
  //         if (error.request) {
  //           errorObject.status = error.request.status;
  //           errorObject.body = JSON.parse(error.request.response);
  //           return errorObject;
  //         }
  //         console.log("error", error);
  //       });
  //   });
  // },

  generateFileUploadURL(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(fileUploadURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  AddChildDocument(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildDocURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  deleteChildDocument(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(ChildDocURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },

  UploadCSVFile(payload) {
    let payloadURL = `${payload.moduleType}/${payload.documentType}?fileName=${payload.fileName}&fileSize=${payload.fileSize}&description=${payload.description}`;
    let newUploadURl = UploadCSVURL + payloadURL;
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(newUploadURl)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  CSVDataBinding(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const CsvHeaderValueCompletedURL = CsvHeaderValue + id;
      return axios
        .get(CsvHeaderValueCompletedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  SaveCsvFile(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ActualImport, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },
  UploadedFileList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(UploadedFilesURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildDocumentList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildDocumentListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  DeleteProfileImage(payload) {
    let payloadURL = `${payload.moduleType}/${payload.documentType}?moduleId=${payload.moduleId}`;
    let newUploadURl = UploadURL + payloadURL;
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(newUploadURl)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  DeleteDocument(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(UploadedFilesURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ListEvents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ListEventsURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  CreateEvents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CreateEventsURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  DeleteEvents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .delete(DeleteEventsURL, { data: payload })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  EditEvents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditEventsURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },
  LinkOrganization(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(LinkOrganizationURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  OrgTypeDetails(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      const OrgDetailsCompleteURL = OrgTypeDetailsPartialURL;
      return axios
        .post(OrgDetailsCompleteURL, { id: id })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildServedReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildServedReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildRedFlagReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildRedFlagReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  DurationFollowupReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(DurationFollowupReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildOverdueReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildOverdueReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  NewlyAddedChildrenReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NewlyAddedChildrenReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  CaseManagementReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CaseManagementReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ExportFile(payload) {
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }

    payload.languageId = langId;
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ExportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  generarateProgressReportList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(generarateProgressReportListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  AverageThrivescaleReport(payload) {
    let prerequest = this.PreRequestCall();
    let langId;
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }
    payload.languageId = langId;
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AverageThriveURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  FollowupDurationReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(FollowupURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildAuditLog(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildAuditURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ChildrenOverdueReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(OverdueURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  NewlyAdmittedChildrenReport(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NewlyAdmittedURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  TransferChildren(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(TransferChildURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  CloseCase(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .patch(CloseCaseURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
          console.log("error", error);
        });
    });
  },
  DisruptionCases(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(DisruptionCasesURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  GetCaseManagement(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CaseManagementURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  CaseworkersServed(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CaseworkerServedURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  FamiliesServed(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(FamiliesServedURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  DashboardTileData(payload) {
    let prerequest = this.PreRequestCall();
    //payload.HTCountryId = localStorage.getItem('userRegion')
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(DashboardOverallURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  DashboardTileDataFS(payload) {
    let prerequest = this.PreRequestCall();
    //payload.HTCountryId = localStorage.getItem('userRegion')
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(FSDashboardOverallURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ChildrenInCCI(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ChildrenInCCIURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  CurrentPlacement(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CurrentPlacementURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  // DurationInCCI() {
  //     //let prerequest = this.PreRequestCall()
  //     return axios
  //       .post(DurationInCCIURL)
  //       .then((response) => {
  //         return response;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   },

  DurationInCCI(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(DurationInCCIURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  ReintegratedChildren(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ReintegratedChildrenURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  NewlyAddedChildren(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NewlyAddedURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  InterventionDetails(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(InterventionDetailsURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  MapDashboardData(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(MapReportURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  AverageChangeTS(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(AverageChangeURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  EditProfile(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(EditProfileURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  },
  UploadUpdatedFile(payload) {
    console.log("called");
    let payloadURL = `${payload.moduleType}/${payload.documentType}/${payload.documentId}?fileName=${payload.fileName}&moduleId=${payload.moduleId}&fileSize=${payload.fileSize}&description=${payload.description}`;
    let newUploadURl = UploadURL + payloadURL;
    return axios
      .put(newUploadURl)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errorObject = {
          status: "",
          body: {},
        };
        if (error.request) {
          errorObject.status = error.request.status;
          errorObject.body = JSON.parse(error.request.response);
          return errorObject;
        }
        console.log("error", error);
      });
  },
  ResendInvitation(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(ResendInvitationURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          console.log("error", error);
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  ListNotifications(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(NotificationListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  UpdateNotification(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .patch(UpdateNotificationURL, payload)
        .then((resonse) => {
          return resonse;
        })
        .catch((error) => {
          let errorObject = {
            status: "",
            body: {},
          };
          if (error.request) {
            errorObject.status = error.request.status;
            errorObject.body = JSON.parse(error.request.response);
            return errorObject;
          }
        });
    });
  },

  saveUserLanguage(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .patch(saveUserLanguageURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ProgressReportListForChild(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ProgressReportListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  viewFollowUpProgress(payload) {
    let prerequest = this.PreRequestCall();
    const currentLanguage = localStorage.getItem("language");
    const currentLanguageList = JSON.parse(
      localStorage.getItem("languageList")
    );
    let langId;
    if (!currentLanguage || !currentLanguageList?.length) {
      langId = "1";
    } else {
      langId =
        currentLanguageList?.length &&
        currentLanguageList.find((item) => item.languageCode == currentLanguage)
          ?.id;
    }

    payload.languageId = langId;
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(viewFollowUpProgressURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getFollowupDomainDetails(assessmentId) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${getFollowupDomainDetailsURL}?assessmentId=${assessmentId}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  generateFollowUpProgressPdf(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(generateFollowUpProgressPdfURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  // child fs
  createFsChild(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(fsChildURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  updateFsChild(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(fsChildURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    });
  },
  getFsChild(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${getFsChildURL}${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getFsChildList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${fsChildListURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getFsChildPlacementStatusList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${getFsChildPlacementStatusURL}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getFsChildDischargeReasonsList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${childDischargeReasonsListURL}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getFsFamilyListForDropdown(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${fsFamilyDropdownURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getChildHistoryList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`child-history`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  //fs family
  createFsParents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(fsParentsURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 400)
            toast.error(error?.response?.data?.description);
        });
    });
  },

  linkFsParents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post("fs-family-link-users", payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    });
  },

  updateFsParents(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(fsParentsURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  createFsFamily(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getFsFamilyURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  updateFsFamily(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(getFsFamilyURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  upateFsFamily(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(getFsFamilyURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getFsFamilyList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${fsFamilyURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getFsFamily(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${getFsFamilyURL}?id=${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  updateFamilyStatus(params) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .patch(`${getFsFamilyURL}/change-activation-status`, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    });
  },
  getEmailFromToken(token) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`/user/decryptUrl?encryptedUrl=${token}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  reverifyUser(token) {
    // let prerequest = this.PreRequestCall();
    return axios.all([]).then((res) => {
      return axios
        .get(`/user/reverifyUser?encryptedUrl=${token}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  createMessage(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(createMessageURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  // fs events

  getEventList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${fsEventsURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  updateMessageAttachment(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(createMessageURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  updateMessageStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(`fs-cancel-scheduled-message`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  createEvent(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${fsEventsActionURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  createScheduleMessage(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(createScheduleMessageURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  updateEvent(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(`${fsEventsActionURL}`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getEvent(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${fsEventsActionURL}?id=${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getMessageList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getMessageListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getEventTypeList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`event-types`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getEventParticipantsList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`event-participants`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getScheduledMessageList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getScheduledMessageListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getFamilyPerCaseWorker(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getFamilyPerCaseWorkerURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getChildPerCaseWorker(country) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getChildPerCaseWorker, { countryName: country })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ListRecLog(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ListRecLogURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  ExportLogPdf(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ExportLogPdfURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  DetailRecLog(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(DetailRecLogURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  MedicationList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(MedicationListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getSignedMedLogDoc(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getSignedMedLogDocURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetFamilyImages(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${GetFamilyImagesURL}?id=${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetLogImages(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getLogImagesDocURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetSupportServiceDetail(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${getSupportServiceDetailURL}?id=${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  GetSupportServiceList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getSupportServiceListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CreateSupportService(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getSupportServiceDetailURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  UpdateSupportService(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(getSupportServiceDetailURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  //Noification apis

  getNotificationList(req) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(ListNotificationsURL, req)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  updateReadNotificationStatus(req) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(ListNotificationsURL, req)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  GetResourceList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getResourceListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  GetResourceCategories(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getResourceCategoriesListURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  GetResourceDetail(id) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${getResourceDetailURL}?id=${id}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CreateNewResource(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(getResourceDetailURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  UpdateResource(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(getResourceDetailURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  // System messages apis

  GetSystemMessagesAfterLogin(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(systemMessageURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  CreateSystemMessages(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(CreateSystemMessagesURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  UpdateSystemMessages(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .put(CreateSystemMessagesURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  SystemMessagesDetails(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(messageDetailURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  UpdateSystemMessageReadStatus(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(updateSystemMessageReadStatusURL, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getSystemMessageList(payload) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${SystemMessageUrl}/list`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  getSystemMessageTypeList() {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .get(`${SystemMessageUrl}/type-list`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  copySystemMessage(param) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${SystemMessageUrl}/copy-message`, param)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  cancelMessage(param) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .patch(`${SystemMessageUrl}/cancel-message`, param)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error("Error canceling message:", error);
          throw error;
        });
    });
  },

  getReadCount(param) {
    let prerequest = this.PreRequestCall();
    return axios.all([prerequest]).then((res) => {
      return axios
        .post(`${SystemMessageUrl}/get-message-viewCount`, param)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error("Error:", error);
          throw error;
        });
    });
  },

  checkIfAppDepricated() {
    // let prerequest = this.PreRequestCall();
    return axios.all([]).then((res) => {
      return axios
        .get(checkIfAppDepricatedURL)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};

export default APIS;
