import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";

const DeactivateFamilyModal = ({
  close,
  deactivateFamilyHandler,
  setIsFamilyActive,
}) => {
  const [deactivateReason, setDeactivateReason] = useState(null);
  return (
    <Box>
      <TextField
        sx={{ mb: 2 }}
        id="discharge-reason"
        fullWidth
        // //onBlur={handleBlur}
        placeholder="Why are you deactivating this family? (Optional)"
        onChange={(e) => setDeactivateReason(e.target.value)}
        value={deactivateReason}
        variant="outlined"
        multiline
        rows={3}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            sx={{ mr: 1, borderRadius: "4px", width: "100%" }}
            variant={"outlined"}
            onClick={() => {
              setDeactivateReason();
              setIsFamilyActive(true);
              close();
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{ mr: 1, borderRadius: "4px", width: "100%" }}
            variant="contained"
            onClick={() => {
              deactivateFamilyHandler(deactivateReason, false);
              close();
            }}
          >
            Deactivate account
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeactivateFamilyModal;
